//
// user.scss
// Use this to write your custom SCSS
//

/**
 * Enable smooth scrolling on the whole document.
 *
 * See https://gomakethings.com/how-to-animate-scrolling-to-anchor-links-with-one-line-of-css/
 */
html {
  scroll-behavior: smooth;

  /**
   * Disable smooth scrolling when users have prefers-reduced-motion enabled.
   */
  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }

  section.container {
    // Offset sticky header for anchor linked sections.
    // See https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
    scroll-margin-top: 3.5rem;
  }
}
