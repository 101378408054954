//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #7fc038;
